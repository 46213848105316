import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { User } from 'ze-api-contract/user-v2/get-current-user';
import { OrganizationValue } from 'ze-api-contract/organization-v2/get-by-name';
import {
  getCanManageProjConfig,
  canMangeConfigKey,
  CanManageProjConfig,
  CanManageConfig,
  getCanManageContributorConfig,
  getCanManageAllContributorConfig,
} from 'app-zephyr-guards/project';
import { useCurrentUser } from './current-user';

export interface CanManageProjParams {
  organization: OrganizationValue | undefined;
  project: string | undefined;
  user: User | undefined;
}

export const queryKeys = createQueryKeys('user-can-manage-proj', {
  can: ({ organization, project, user }: CanManageProjParams) => ({
    queryKey: [canMangeConfigKey, organization, project, user],
    queryFn: async () => {
      if (!organization || !project || !user)
        return {
          updateProject: false,
          updateProjectName: false,
          updateProjectSettings: false,
          deleteProject: false,
          manageContributor: false,
        } as CanManageProjConfig;
      return getCanManageProjConfig(organization, user);
    },
  }),
  contributor: ({ organization, project, user }: CanManageProjParams) => ({
    queryKey: [canMangeConfigKey, organization, project, user],
    queryFn: async () => {
      if (!organization || !project || !user)
        return {
          can: false,
        } as CanManageConfig;
      return getCanManageContributorConfig(organization, user);
    },
  }),
  allContributor: ({ organization, project, user }: CanManageProjParams) => ({
    queryKey: [canMangeConfigKey, organization, project, user],
    queryFn: async () => {
      if (!organization || !project || !user) return undefined;
      return getCanManageAllContributorConfig(organization, user);
    },
  }),
});

export function useUserCanManageProj(params: Omit<CanManageProjParams, 'user'>) {
  const { user } = useCurrentUser();
  const { isLoading, data: can, error } = useQuery(queryKeys.can({ ...params, user }));

  return {
    can,
    isLoading,
    error,
  };
}

export function useUserCanManageContributor(params: Omit<CanManageProjParams, 'user'>) {
  const { user } = useCurrentUser();
  const { isLoading, data: can, error } = useQuery(queryKeys.contributor({ ...params, user }));

  return {
    can,
    isLoading,
    error,
  };
}

export function useUserCanManageAllContributor(params: Omit<CanManageProjParams, 'user'>) {
  const { user } = useCurrentUser();
  const { isLoading, data: can, error } = useQuery(queryKeys.allContributor({ ...params, user }));

  return {
    can,
    isLoading,
    error,
  };
}
