import { Link } from '@tanstack/react-router';
import clsx from 'clsx';
// components
import { LogoMobileZephyrIcon } from 'app-zephyr-icons';
import { NavbarActionMenu } from 'app-zephyr-components/NavbarActionMenu';
import ProfileMenu from 'app-zephyr-components/ProfileMenu';
import { Button } from '../../../Button';
import { TopNavigation } from '../top-navigation';
import { BreadCrumbs } from '../bread-crumbs';
// constants
import { INavItem } from 'app-zephyr-constants';
import { RouteNames } from 'app-zephyr-routes';
// styles
import { useStyles } from './styles';

export interface HeaderProps {
  /**
   * top header navigation items
   */
  navigationItems: INavItem[];
  canCreateProject?: boolean;
  canCreateTeam?: boolean;
  username?: string;
  name?: string;
}

export function Header({ navigationItems, canCreateProject, canCreateTeam, username, name }: Readonly<HeaderProps>) {
  const { classes } = useStyles();

  return (
    <div data-e2e="LAYOUT_HEADER__HEADER">
      {' '}
      <div className={clsx(classes.headerContainer)}>
        <div className={classes.header}>
          <div className={classes.toolbar}>
            <div className={classes.logoContainer}>
              <Link to={RouteNames.PERSONAL_DASHBOARD} data-e2e="LAYOUT_HEADER__LOGO">
                <LogoMobileZephyrIcon />
              </Link>
              <BreadCrumbs username={username} name={name} />
            </div>
            <div className={classes.menuContainer} data-e2e="LAYOUT_HEADER__PROFILE_MENU">
              <a
                href="https://docs.zephyr-cloud.io/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.documentationLink}
              >
                <Button size="small" variant="outlined" className={classes.documentationButton}>
                  <span>Documentation</span>
                </Button>
              </a>
              <NavbarActionMenu canCreateProject={canCreateProject} canCreateTeam={canCreateTeam} />
              <ProfileMenu containerCollapse={true} />
            </div>
          </div>
        </div>
        <div className={clsx(classes.toolbar, 'navigation border-bottom', { hidden: !navigationItems.length })}>
          <TopNavigation navigationItems={navigationItems} />
        </div>
      </div>
    </div>
  );
}
