import { authenticated_user_profile_path, organization_team_path, user_path } from 'app-zephyr-routes';

interface GetItemPathProps {
  name?: string; // should be checked, it seems it is unnecessary
  type?: string;
  teamId?: string;
  organization: string | undefined;
  authUserName: string | undefined;
  username?: string;
}

export function getItemPath({ type, authUserName, teamId, organization, username }: GetItemPathProps): string {
  if (type === 'team') return organization_team_path({ organization: { name: organization }, team: { id: teamId } });

  return authUserName === username ? authenticated_user_profile_path() : user_path({ username });
}
