import { ReactNode } from 'react';
import { Tooltip, Stack, TooltipProps } from '@mui/material';

type TooltipWrapperProps = {
  title: string | ReactNode;
  children: ReactNode;
} & TooltipProps;

export const TooltipWrapper = ({ title, children, placement }: TooltipWrapperProps) => {
  return (
    <Tooltip title={title} arrow placement={placement ?? 'top'}>
      <Stack>{children}</Stack>
    </Tooltip>
  );
};
