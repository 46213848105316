import { simplePath } from 'ze-api-contract/utils';

export const GET_APPLICATION_ENVIRONMENT_LIST = '/v2/application/:organization/:project/:application/environment';

interface GetApplicationEnvironmentListParams {
  organization: string;
  project: string;
  application: string;
}

export function get_application_environment_list_path(params: GetApplicationEnvironmentListParams): string {
  return simplePath(GET_APPLICATION_ENVIRONMENT_LIST, params);
}

export enum ApplicationEnvironmentListSortField {
  Name = 'name',
  Version = 'version',
  UpdatedAt = 'updatedAt',
}

export enum ApplicationEnvironmentListQueryField {
  Name = 'name',
  Version = 'version',
  Condition = 'condition',
  UpdatedDate = 'updatedDate',
  UpdatedPeriod = 'updatedPeriod',
}
