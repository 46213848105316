import { useAuth0 } from '@auth0/auth0-react';
import { Link } from '@tanstack/react-router';
import React, { MouseEvent, useState } from 'react';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useCurrentUser } from 'app-zephyr-domains/user';
import { ApplicationIcon, PeopleIcon, ProjectsIcon } from 'app-zephyr-icons';
import { PersonIcon } from 'app-zephyr-icons/Person';
import {
  organization_managed_applications_path,
  organization_projects_path,
  RouteNames,
  authenticated_user_profile_path,
  useTypedParams,
} from 'app-zephyr-routes';
import { getAbbreviation } from 'app-zephyr-utils';

import { Avatar } from '../Avatar';
import { useStyles } from './styles';

interface ProfileMenuProps {
  containerCollapse?: boolean;
}

export const ProfileMenu = ({ containerCollapse = false }: ProfileMenuProps) => {
  // global state
  const { classes, theme } = useStyles();
  const { organization } = useTypedParams();
  const { logout } = useAuth0();

  // local state
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { user: userApi } = useCurrentUser();

  if (!userApi) return null;

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    closeMenu();
    await logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <>
      <List className={classes.profileContainer}>
        <ListItem
          className={classes.profileItem}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <ListItemAvatar className={classes.profileAvatarContainer} data-e2e="PROFILE_MENU__USER_AVATAR">
            <Avatar
              variant="circular"
              alt={organization}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
              backgroundColor={userApi.avatarColor ?? '#A8296E'}
              width={36}
              height={36}
            >
              {userApi.portrait ? (
                <img src={userApi.portrait} alt={userApi.name} width="36px" height="36px" />
              ) : (
                getAbbreviation(userApi.name)
              )}
            </Avatar>
          </ListItemAvatar>
          <KeyboardArrowDownIcon
            sx={{
              color: theme.palette.tx.primary,
              transition: 'all .2s ease',
              transform: anchorEl ? 'rotate(180deg)' : null,
            }}
          />
        </ListItem>
      </List>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        className={classes.menuContainer}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        data-e2e="PROFILE_MENU__MENU"
      >
        <MenuItemLink href={authenticated_user_profile_path()} onClick={closeMenu} icon={<PersonIcon />}>
          My Profile
        </MenuItemLink>
        <Divider className={classes.divider} />
        {organization && (
          <MenuItemLink
            href={organization_projects_path({ organization: { name: organization } })}
            onClick={closeMenu}
            icon={<ProjectsIcon />}
          >
            Your Projects
          </MenuItemLink>
        )}
        {organization && (
          <MenuItemLink
            href={organization_managed_applications_path({ organization: { name: organization } })}
            onClick={closeMenu}
            icon={<ApplicationIcon />}
          >
            Your Applications
          </MenuItemLink>
        )}
        <MenuItemLink href={RouteNames.PERSONAL_DASHBOARD} onClick={closeMenu} icon={<PeopleIcon />}>
          Your Organizations
        </MenuItemLink>
        <Divider className={classes.divider} />
        <MenuItemLink onClick={handleLogout} className={classes.logout}>
          Sign Out
        </MenuItemLink>
      </Menu>
    </>
  );
};

interface MenuItemLink {
  href?: string;
  hasSubMenu?: boolean;
  onClick?: (e: MouseEvent) => void;
  children: React.ReactNode;
  className?: string;
  icon?: React.ReactElement;
}

function MenuItemLink({ href, hasSubMenu, onClick, children, className, icon }: Readonly<MenuItemLink>) {
  return (
    <MenuItem onClick={onClick}>
      {!hasSubMenu && (
        <Link
          to={href}
          style={{ width: '100%' }}
          className={className}
          data-e2e={`PROFILE_MENU__${children?.toString().toUpperCase().replace(' ', '_') ?? ''}`}
        >
          {children}
          {icon && <ListItemIcon className="iconContainer">{icon}</ListItemIcon>}
        </Link>
      )}

      {hasSubMenu && (
        <>
          <ArrowLeftIcon sx={{ width: '24px', height: '24px' }} />
          <span data-e2e={`PROFILE_MENU__${children?.toString().toUpperCase().replace(' ', '_') ?? ''}`}>
            {children}
          </span>{' '}
          {icon && <ListItemIcon className="iconContainer">{icon}</ListItemIcon>}
        </>
      )}
    </MenuItem>
  );
}

export default ProfileMenu;
