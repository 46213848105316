import { SVGProps } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  icon: {
    '&:hover path': {
      fill: theme.palette.brand.turquoise[300],
    },
  },
}));

export type OpenExternalIconProps = SVGProps<SVGSVGElement> & {
  color?: string;
};

export function OpenExternal(props: OpenExternalIconProps) {
  const { classes } = useStyles();

  return (
    <svg
      width={props.width ?? 24}
      height={props.height ?? 24}
      viewBox="-2 -2 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={classes.icon}
    >
      <path
        d="M16 16.5H2V2.5H9V0.5H2C0.89 0.5 0 1.4 0 2.5V16.5C0 17.6 0.89 18.5 2 18.5H16C17.1 18.5 18 17.6 18 16.5V9.5H16V16.5ZM11 0.5V2.5H14.59L4.76 12.33L6.17 13.74L16 3.91V7.5H18V0.5H11Z"
        fill={props.color ?? '#27584D'}
      />
    </svg>
  );
}
