import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  contentBox: {
    border: theme.palette.border.secondary,
    borderRadius: theme.borderRadius.md,
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  },
  loading: {
    '@keyframes rotation': {
      from: {
        transform: 'rotate(360deg)',
      },
      to: {
        transform: 'rotate(0deg)',
      },
    },
    fill: `${theme.palette.tx.disabled as string} !important`,
    animation: 'rotation 2s infinite linear',
  },
}));
