import { Select, MenuItem, InputLabel, Autocomplete, TextField, OutlinedInput } from '@mui/material';
import {
  Path,
  PathValue,
  FieldValues,
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  FieldErrors,
  FieldName,
} from 'react-hook-form';

import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';

import { TooltipWrapper } from 'app-zephyr-components/Tooltip-wrapper';

import { InputProps, ErrorMessage as FormErrorMessage } from '../../_elements';

import { useStyles } from './styles';

export enum MultiSelectValueKeys {
  TYPE = 'type',
  VALUE = 'value',
}

export interface MultiSelectValueItem {
  label: string;
  value: string;
}

export interface MultiSelectValue {
  [MultiSelectValueKeys.TYPE]: MultiSelectValueItem;
  [MultiSelectValueKeys.VALUE]: MultiSelectValueItem;
}

export interface FormMultiselectProps<FormValuesProps extends FieldValues> extends Omit<InputProps, 'name'> {
  /**
   * input and error name
   */
  name: Path<FormValuesProps>;
  /**
   * react-hook-form control
   */
  control: Control<FormValuesProps>;
  /**
   * react-hook-form getValues
   */
  getValues: UseFormGetValues<FormValuesProps>;
  /**
   * react-hook-form setValue
   */
  setValue: UseFormSetValue<FormValuesProps>;
  /**
   * react-hook-form trigger
   */
  trigger: UseFormTrigger<FormValuesProps>;
  /**
   * first/second selects options
   */
  options: {
    [MultiSelectValueKeys.TYPE]: MultiSelectValueItem[];
    [MultiSelectValueKeys.VALUE]: MultiSelectValueItem[];
  };

  watch?: UseFormWatch<FormValuesProps>;
  /**
   * errors object
   */
  errors?: FieldErrors<FormValuesProps>;
  // can be renamed when we will decide how we will call items
  onFirstSelectChange?: (data: MultiSelectValueItem) => void;
  onChange?: () => void;

  inputType?: 'text' | 'multiselect';
  tooltip?: string;
}

const FormMultiselect = <FormValuesProps extends Record<string, unknown>>({
  name,
  errors,
  className,
  label,
  control,
  getValues,
  setValue,
  trigger,
  options,
  disabled,
  onFirstSelectChange,
  onChange,
  inputType,
  tooltip,
}: FormMultiselectProps<FormValuesProps>) => {
  const { classes, cx } = useStyles();

  // value is not always an array when it's a 'target' condition and 'ios' or 'android' is the value
  const isEmptyValues = Array.isArray(options[MultiSelectValueKeys.VALUE])
    ? options[MultiSelectValueKeys.VALUE].length === 0
    : false;

  const handleSelectChange = (selectName: keyof MultiSelectValue, newValue?: MultiSelectValueItem) => {
    if (!newValue) return;
    const currentValues = getValues(name) as MultiSelectValue;
    const newMultiSelectValue = { ...currentValues, [selectName]: newValue } as PathValue<
      FormValuesProps,
      Path<FormValuesProps>
    >;
    setValue(name, newMultiSelectValue, { shouldValidate: true, shouldDirty: true });
    void trigger(name);
    if (onChange) onChange();
  };

  const getUpdatedTypeValue = (value: string): MultiSelectValueItem | undefined => {
    return options[MultiSelectValueKeys.TYPE].find((item) => item.value === value);
  };

  const getTextValueField = (value: string): MultiSelectValueItem => {
    return { value, label: value };
  };

  const onFirstChangeHandler = (value: string) => {
    if (!onFirstSelectChange) return;

    const refvalue = getUpdatedTypeValue(value);
    if (!refvalue) return;

    onFirstSelectChange(refvalue);
  };

  return (
    <div className={cx(classes.container, className)} aria-live="polite" data-testid="form-multiselect">
      <TooltipWrapper title={tooltip ? tooltip : ''}>
        <>
          {!!label && <InputLabel className={classes.label}>{label}</InputLabel>}
          <div className={classes.selectContainer}>
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    className={classes.typeSelect}
                    onChange={(e) => {
                      handleSelectChange(MultiSelectValueKeys.TYPE, getUpdatedTypeValue(e.target.value));
                      onFirstChangeHandler(e.target.value);
                    }}
                    defaultValue={options[MultiSelectValueKeys.TYPE][0].value}
                    value={(field.value as MultiSelectValue)[MultiSelectValueKeys.TYPE].value}
                  >
                    {options.type.map((option: MultiSelectValueItem) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />

            {inputType !== 'text' && (
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    className={classes.searchSelect}
                    options={options.value}
                    getOptionLabel={(option) => {
                      return option.label;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={isEmptyValues ? 'No options available' : null}
                        placeholder="Fill the value or choose the valid one"
                      />
                    )}
                    onChange={(e, value) => {
                      handleSelectChange(MultiSelectValueKeys.VALUE, value ?? { label: '', value: '' });
                    }}
                    disabled={isEmptyValues}
                    value={(field.value as MultiSelectValue).value}
                  />
                )}
              />
            )}

            {inputType === 'text' && (
              <Controller
                name={name}
                control={control}
                render={({ field }) => {
                  return (
                    <OutlinedInput
                      {...field}
                      className={classes.textInput}
                      inputProps={{
                        id: `${name}_condition_id`,
                        'aria-label': label,
                        itemType: 'text',
                      }}
                      disabled={disabled}
                      value={(field.value as MultiSelectValue).value.label}
                      onChange={(data) => {
                        handleSelectChange(MultiSelectValueKeys.VALUE, getTextValueField(data.target.value));
                      }}
                      fullWidth
                      placeholder="Put a value"
                    />
                  );
                }}
              />
            )}
          </div>
          <ErrorMessage
            errors={errors}
            name={
              `${name}.value[${MultiSelectValueKeys.TYPE}]` as unknown as FieldName<
                FieldValuesFromFieldErrors<FieldErrors<FormValuesProps>>
              >
            }
            render={({ message }) => <FormErrorMessage className={'error-message'}>{message}</FormErrorMessage>}
          />
          <ErrorMessage
            errors={errors}
            name={
              `${name}.value[${MultiSelectValueKeys.VALUE}]` as unknown as FieldName<
                FieldValuesFromFieldErrors<FieldErrors<FormValuesProps>>
              >
            }
            render={({ message }) => (
              <FormErrorMessage className={'error-message value-error-message'}>{message}</FormErrorMessage>
            )}
          />
        </>
      </TooltipWrapper>
    </div>
  );
};

export { FormMultiselect };
