import { FormEvent } from 'react';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';

import { Button } from 'app-zephyr-components/Button';
import { UpdateUserTokenReq } from 'ze-api-contract/user-auth-token/user-auth-token.interface';
import { TooltipWrapper } from 'app-zephyr-components/Tooltip-wrapper';

import { FormContainer } from '../../_elements';
import { FormInput, FormTextArea } from '../../controls';
import { useStyles } from './styles';
import { TokenUpdate } from './interfaces';

export type TokenFormFields = TokenUpdate & Record<string, string>;

interface TokenProps {
  onSubmit: (value: UpdateUserTokenReq) => Promise<void>;
  token: TokenUpdate;
  onCancel: () => void;
}

const EditUserTokenForm = ({ onSubmit, token, onCancel }: TokenProps) => {
  const { classes } = useStyles();
  const {
    register,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm<TokenFormFields>({
    defaultValues: token as TokenFormFields,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const submitHandler = async (event: FormEvent) => {
    event.preventDefault();
    const data = getValues();
    await onSubmit({ description: data.description });
  };

  return (
    <form
      onSubmit={(event) => {
        void submitHandler(event);
      }}
      data-e2e={'EDIT_USER_TOKEN_FORM'}
    >
      <FormContainer title={'Edit token'}>
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={2}>
            <FormInput<TokenFormFields>
              id="name"
              name="name"
              label="Name"
              register={register}
              errors={errors}
              disabled={!!token}
            />
            <FormInput<TokenFormFields>
              id="expiryDays"
              name="expiryDays"
              label="Will be expired in"
              register={register}
              errors={errors}
              disabled={!!token}
            />
          </Stack>

          <FormTextArea<TokenFormFields>
            id="description"
            name="description"
            label="Description"
            register={register}
            errors={errors}
          />
        </Stack>
      </FormContainer>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} marginTop={2}>
        <TooltipWrapper title={'Navigate back to existing api token list.'}>
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              onCancel();
            }}
          >
            Cancel
          </Button>
        </TooltipWrapper>

        <Button type="submit" disabled={!isDirty} className={classes.confirmBtn}>
          Edit token
        </Button>
      </Stack>
    </form>
  );
};

export { EditUserTokenForm };
