export enum Channel {
  Tag = 'tag',
  Version = 'version',
}

export const channelList = [Channel.Tag, Channel.Version];

// TODO: use correct type (remove undefined form type)
export interface ConditionValue {
  value: string;
  label: string;
}

export interface Condition {
  channel: Channel;
  value: ConditionValue;
}

export interface Version {
  id: string;
  name: string;
  remote_host: string | null;
}

export interface ApplicationEnvironment {
  id: string;
  name: string;
  condition: Condition;
  customDnsNames: string[];
  applicationVersionId: string;
  // TODO: remove `undefined`
  version: Version | undefined;
  description: string;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
  remote_host: string;
  remote_entry_url: string;
  context?: {
    isCI?: boolean;
  };
  author?: {
    id: string;
    name: string;
    email: string;
    username: string;
    portrait: string | null;
    avatarColor: string;
  };
}

export interface Error {
  message: string;
  response: {
    data: {
      errorMessage: string;
    };
  };
}

export interface ModifyCustomDomainRequest {
  customDomain: string;
}
