enum VALIDATION_ERROR_MESSAGES {
  required = 'This field is required',
  maxLength = 'This field is too long',
  minLength = 'This field is too short ',
  patternDisplayNameOrgNameProjectTeam = 'The letters, numbers, underscores, dashes, spaces and apostrophes are available',
  patternDomain = 'Please enter a valid domain, such as @example.com',
  patternOrganizationName = 'The lowercased letters, numbers, underscores and dashes are available',
  patternNoSpacesAvailable = 'Only the letters, numbers, underscores, dashes, are available',
  patternOnlyDNSAvailable = 'Only domains and subdomains are available',
  condition = 'Condition is required',
  conditionType = 'Condition type is required',
  versionPatternInfo = 'The "version" must be in format "major.minor.patch" (e.g., 1.0.0)',
  versionInteger = 'Only the integer numbers are available',
  versionNumber = 'Only the numbers are available',
  descriptionEmpty = 'The description cannot be empty',
  descriptionShort = 'Description is too short',
  descriptionLong = 'Description is too long',
  asciiValidation = 'Character should be in the valid ASCII range',
  linkValidation = 'Please enter the valid link',
  emailValidation = 'Please enter the valid email',
  urlValidation = 'Please enter URL, not PathName',
}

export enum JOI_VALIDATIONS_FIELDS {
  emptyString = 'string.empty',
  minString = 'string.min',
  maxString = 'string.max',
  patternString = 'string.pattern.base',
}

//regexp(apostrophes, spaces, letters, numbers, underscores, dashes)
const organizationNameRegexp = /^[a-z0-9_-]+$/;
const organizationDisplayNameRegexp =
  /^(?=[\x20-\x7F]+$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9_.\-@']+(\s[a-zA-Z0-9_.\-@']+)*\s*$/;
const profileNameRegexp = /^(?=[\x20-\x7F]+$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9_.\-@']+(\s[a-zA-Z0-9_.\-@']+)*\s*$/;
const teamNameRegexp = /^(?=[\x20-\x7F]+$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9_\-']+(\s[a-zA-Z0-9_\-']+)*\s*$/;
const projectNameRegexp = /^(?=[\x20-\x7F]+$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9_\-']+(\s[a-zA-Z0-9_\-']+)*\s*$/;
const projectDisplayNameRegexp = /^(?=[\x20-\x7F]+$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9_.\-@']+(\s[a-zA-Z0-9_.\-@']+)*\s*$/;
//regexp(no apostrophes, no spaces)
const applicationNameRegexp = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9_-]+$/;
const applicationTagNameRegexp = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9_-]+$/;
const applicationEnvironmentNameRegexp = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9_-]+$/;
const applicationTagConditionValue = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9_/. ^~><=|-]+$/;
const domainsRegexp = /^@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
const domainsStringWithComaSeparator =
  /^\s*(?:(?:\w+(?:-+\w+)*\.)+[a-z]+)\s*(?:\s*(?:(?:\w+(?:-+\w+)*\.)+[a-z]+)\s*)*$/;
// eslint-disable-next-line no-control-regex
const asciiStringRegexp = /^[\x00-\x7F]+$/;
const urlWithoutPathnameRegexp = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?::\d{1,5})?$/;
const integrationNameRegexp = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9_-]+$/;

export interface ErrorMessagesJoiStringInterface {
  'string.empty': string;
  'string.min': string;
  'string.max': string;
  'string.pattern.base': string;
}

export interface NameValidationInterface {
  required: string;
  minLength: { value: number; message: string };
  maxLength: { value: number; message: string };
  pattern: { value: RegExp; message: string };
}

export interface StringOptionalValidationInterface {
  minLength?: { value: number; message: string };
  maxLength: { value: number; message: string };
  pattern: { value: RegExp; message: string };
}

//Validation for string format '1.0.0'
export const APPLICATION_VERSION_FIELD_VALIDATION_REGEXP = {
  minLength: 3,
  maxLength: 15,
  pattern: /^(?=[\x20-\x7F]+$)([0-9]\d*\.){2}[0-9]\d*$/,
};

export const APPLICATION_CONDITION_FIELD_VALIDATION_REGEXP = {
  minLength: 3,
  maxLength: 100,
  pattern: applicationTagConditionValue,
};

export const APPLICATION_DNS_FIELD_VALIDATION_REGEXP = {
  minLength: 3,
  maxLength: 500,
  pattern: domainsStringWithComaSeparator,
};

//organizationValidations
export const ORGANIZATION_NAME_JOI_VALIDATOR_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternOrganizationName,
};

export const ORGANIZATION_NAME_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 30, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: organizationNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternOrganizationName,
  },
};

export const ORGANIZATION_DOMAIN_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 50, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: domainsRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternDomain,
  },
};

export const ORGANIZATION_DISPLAY_NAME_JOI_VALIDATOR_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
};

export const ORGANIZATION_DISPLAY_NAME_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 50, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: organizationDisplayNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
  },
};

export const APPLICATION_CONDITION_TEXT_VALUE = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: {
    value: APPLICATION_CONDITION_FIELD_VALIDATION_REGEXP.minLength,
    message: VALIDATION_ERROR_MESSAGES.minLength,
  },
  maxLength: {
    value: APPLICATION_CONDITION_FIELD_VALIDATION_REGEXP.maxLength,
    message: VALIDATION_ERROR_MESSAGES.maxLength,
  },
  pattern: {
    value: APPLICATION_CONDITION_FIELD_VALIDATION_REGEXP.pattern,
    message: VALIDATION_ERROR_MESSAGES.patternNoSpacesAvailable,
  },
};

//teamNameValidation
export const TEAM_NAME_JOI_VALIDATOR_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
};

export const TEAM_NAME_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 30, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: teamNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
  },
};

//projectNameValidation
export const PROJECT_NAME_JOI_VALIDATOR_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
};

export const PROJECT_NAME_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 30, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: projectNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
  },
};

export const PROJECT_DISPLAY_NAME_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 50, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: projectDisplayNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
  },
};

//applicationNameValidation
export const APPLICATION_NAME_JOI_VALIDATOR_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternNoSpacesAvailable,
};

export const APPLICATION_NAME_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 30, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: applicationNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternNoSpacesAvailable,
  },
};

export const APPLICATION_DISPLAY_NAME_JOI_VALIDATOR_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
};

export const APPLICATION_DISPLAY_NAME_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 30, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: projectDisplayNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
  },
};
//applicationEnvironmentNameValidation
export const APPLICATION_ENVIRONMENT_NAME_JOI_VALIDATOR_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternNoSpacesAvailable,
};

export const APPLICATION_ENVIRONMENT_NAME_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 200, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: applicationEnvironmentNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternNoSpacesAvailable,
  },
};

//applicationTagNameValidation
export const APPLICATION_TAG_NAME_JOI_VALIDATOR_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternNoSpacesAvailable,
};

export const APPLICATION_TAG_NAME_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 200, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: applicationTagNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternNoSpacesAvailable,
  },
};
//applicationConditionsValidation
export const APPLICATION_CONDITION_FIELD_JOI_VALIDATOR_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternNoSpacesAvailable,
};

// application dns validation
export const APPLICATION_DNS_FIELD_JOI_VALIDATION_MESSAGES = {
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternOnlyDNSAvailable,
};

export const requiredFieldJoiErrorMessage = {
  'any.required': VALIDATION_ERROR_MESSAGES.required,
  'object.base': VALIDATION_ERROR_MESSAGES.required,
};

//DescriptionFieldJOIValidation
export const validationErrorMessagesDescriptionField = {
  'string.empty': VALIDATION_ERROR_MESSAGES.descriptionEmpty,
  'string.min': VALIDATION_ERROR_MESSAGES.descriptionShort,
  'string.max': VALIDATION_ERROR_MESSAGES.descriptionLong,
  // 'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternNoSpacesAvailable,
};

export const PROFILE_NAME_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 75, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: profileNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
  },
};

export const PROFILE_NAME_JOI_VALIDATOR_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternNoSpacesAvailable,
};

export const DESCRIPTION_VALIDATOR: StringOptionalValidationInterface = {
  maxLength: { value: 200, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: asciiStringRegexp,
    message: VALIDATION_ERROR_MESSAGES.asciiValidation,
  },
};

export const USERNAME_VALIDATOR: StringOptionalValidationInterface = {
  maxLength: { value: 75, message: VALIDATION_ERROR_MESSAGES.maxLength },
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  pattern: {
    value: profileNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
  },
};

export const DESCRIPTION_JOI_VALIDATOR_MESSAGES = {
  'string.max': DESCRIPTION_VALIDATOR.maxLength.message,
  'string.pattern.base': DESCRIPTION_VALIDATOR.pattern.message,
};

export const EMAIL_JOI_VALIDATOR_MESSAGES = {
  'string.email': VALIDATION_ERROR_MESSAGES.emailValidation,
};

export const LINKS_JOI_VALIDATOR_MESSAGES = {
  'string.uri': VALIDATION_ERROR_MESSAGES.linkValidation,
};

export const URL_STRING_JOI_VALIDATION_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.uri': VALIDATION_ERROR_MESSAGES.linkValidation,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.urlValidation,
};

export const URL_STRING_VALIDATOR = {
  pattern: urlWithoutPathnameRegexp,
  message: VALIDATION_ERROR_MESSAGES.urlValidation,
};

export const DEPLOY_INTEGRATION_NAME_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 75, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: integrationNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
  },
};

export const DEPLOY_INTEGRATION_NAME_JOI_VALIDATOR_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternNoSpacesAvailable,
};

export const PROVIDER_JOI_VALIDATOR = {
  required: VALIDATION_ERROR_MESSAGES.required,
  maxLength: { value: 75, message: VALIDATION_ERROR_MESSAGES.maxLength },
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
};

export const USER_TOKEN_NAME_VALIDATOR: NameValidationInterface = {
  required: VALIDATION_ERROR_MESSAGES.required,
  minLength: { value: 3, message: VALIDATION_ERROR_MESSAGES.minLength },
  maxLength: { value: 75, message: VALIDATION_ERROR_MESSAGES.maxLength },
  pattern: {
    value: profileNameRegexp,
    message: VALIDATION_ERROR_MESSAGES.patternDisplayNameOrgNameProjectTeam,
  },
};

export const USER_TOKEN_NAME_JOI_VALIDATOR_MESSAGES = {
  'string.empty': VALIDATION_ERROR_MESSAGES.required,
  'string.min': VALIDATION_ERROR_MESSAGES.minLength,
  'string.max': VALIDATION_ERROR_MESSAGES.maxLength,
  'string.pattern.base': VALIDATION_ERROR_MESSAGES.patternNoSpacesAvailable,
};
