import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { axios } from 'app-zephyr-axios';
import { delete_application_tag_path } from 'ze-api-contract/application-v2/application-tag/delete';
import {
  GET_APPLICATION_TAG_CONDITION_OPTIONS,
  get_application_tag_conditions_path,
  ApplicationTagConditionOptions,
} from 'ze-api-contract/application-v2/application-tag/get-condition-options';
import {
  CreateApplicationTag,
  create_application_tag_path,
} from 'ze-api-contract/application-v2/application-tag/create';
import { application_settings_tags_path } from 'app-zephyr-routes';
import {
  get_application_tag_condition_list_path,
  ConditionVersion as ApplicationVersion,
  GET_APPLICATION_TAG_CONDITION_VERSION,
} from 'ze-api-contract/application-v2/application-tag/get-condition-version';
import {
  GET_APPLICATION_TAG,
  get_application_tag_path,
  ApplicationTagValue,
} from 'ze-api-contract/application-v2/application-tag/get';
import { Channel, SearchCondition } from 'ze-api-contract/application-v2/application-tag/interfaces';

import { applicationTagListQuery } from './application-tag-list';
export interface GetApplicationTagListParams {
  organization?: string;
  project?: string;
  application?: string;
}

export interface GetApplicationParams {
  organization?: string;
  project?: string;
  application?: string;
  tag?: string;
}

export interface TagFormCondition {
  type: { label: string; value: string };
  value: { value: string; label: string };
}

export type ConditionVersionParam = SearchCondition[];

interface GetApplicationTagVersionParams {
  organization?: string;
  project?: string;
  application?: string;
  conditions: ConditionVersionParam;
  tag?: string;
}

export const TYPE_LABELS: Record<Channel, string> = {
  [Channel.Version]: 'Version',
  [Channel.Branch]: 'Branch',
  [Channel.User]: 'User',
  [Channel.isCI]: 'CI',
  [Channel.Target]: 'Target',
};

export const applicationTagQuery = createQueryKeys('application-tag', {
  getApplicationTagConditions: ({ organization, project, application }: GetApplicationTagListParams) => ({
    queryKey: [GET_APPLICATION_TAG_CONDITION_OPTIONS, organization, project, application],
    queryFn: async () => {
      if (!organization || !project || !application) return Promise.resolve(undefined);
      return axios
        .get<{
          value: ApplicationTagConditionOptions;
        }>(get_application_tag_conditions_path({ organization, project, application }))
        .then((res) => res.data.value);
    },
  }),
  getApplicationTagVersion: ({
    organization,
    project,
    application,
    conditions,
    tag,
  }: GetApplicationTagVersionParams) => ({
    queryKey: [
      GET_APPLICATION_TAG_CONDITION_VERSION,
      organization,
      project,
      application,
      tag,
      JSON.stringify(conditions),
      conditions.length,
    ],
    queryFn: async () => {
      if (!organization || !project || !application || !conditions.length) return Promise.resolve(undefined);

      return axios
        .get<{
          value: ApplicationVersion;
        }>(get_application_tag_condition_list_path({ organization, project, application }), {
          params: { conditions: encodeURI(JSON.stringify(conditions)) },
        })
        .then((res) => res.data.value);
    },
  }),
  getApplicationTag: ({ organization, project, application, tag }: GetApplicationParams) => ({
    queryKey: [GET_APPLICATION_TAG, organization, project, application, tag],
    queryFn: async () => {
      if (!organization || !project || !application || !tag) return Promise.resolve(undefined);

      return axios
        .get<{ value: ApplicationTagValue }>(get_application_tag_path({ organization, project, application, tag }))
        .then((res) => res.data.value);
    },
  }),
});

export function useApplicationTagConditions(params: GetApplicationTagListParams) {
  const { isLoading, data: conditions, error } = useQuery(applicationTagQuery.getApplicationTagConditions(params));

  return { conditions, isLoading, error };
}

export function useCreateTag(params: Required<GetApplicationTagListParams>) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { queryKey } = applicationTagListQuery.getApplicationTagList(params);

  return useMutation({
    mutationFn: (tag: CreateApplicationTag) =>
      axios.post<{ value: CreateApplicationTag }>(create_application_tag_path(params), tag),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
      void navigate({
        to: application_settings_tags_path({
          organization: { name: params.organization },
          project: { name: params.project },
          application: { name: params.application },
        }),
      });
    },
  });
}

export function useEditTag(params: Required<GetApplicationParams>) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { queryKey } = applicationTagListQuery.getApplicationTagList(params);

  return useMutation({
    mutationFn: (tag: CreateApplicationTag) =>
      axios.post<{ value: CreateApplicationTag }>(get_application_tag_path(params), tag),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
      void navigate({
        to: application_settings_tags_path({
          organization: { name: params.organization },
          project: { name: params.project },
          application: { name: params.application },
        }),
      });
    },
  });
}

export function useRemoveTag(params: Required<GetApplicationParams>) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { queryKey } = applicationTagListQuery.getApplicationTagList(params);

  return useMutation({
    mutationFn: () => axios.delete(delete_application_tag_path(params)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
      void navigate({
        to: application_settings_tags_path({
          organization: { name: params.organization },
          project: { name: params.project },
          application: { name: params.application },
        }),
      });
    },
  });
}

export function useApplicationTagVersion(params: GetApplicationTagVersionParams) {
  const { isLoading, data: versionValue, error } = useQuery(applicationTagQuery.getApplicationTagVersion(params));
  return { versionValue, isLoading, error };
}

export function useApplicationTag(params: GetApplicationParams) {
  const { isLoading, data: tag, error } = useQuery(applicationTagQuery.getApplicationTag(params));
  return { tag, isLoading, error };
}
