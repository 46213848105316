import { MINUTES_IN_DAY } from 'app-zephyr-constants';

const isValidDate = (date: Date): boolean => date instanceof Date && !isNaN(date.getTime());

const initialDateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
};

const formatDate = (date?: Date | string, options: Intl.DateTimeFormatOptions = {}): string => {
  if (!date) return '---';

  const DateTimeFormat = new Intl.DateTimeFormat('en-US', Object.assign(initialDateOptions, options));
  return DateTimeFormat.format(new Date(date));
};

const formatDateTime = (date: string | undefined) => {
  if (!date) return '';

  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
};

const getMinutesInCurrentMonth = (): number => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const daysInMonth = new Date(year, month, 0).getDate();
  return daysInMonth * MINUTES_IN_DAY;
};

const getGMTInfo = () => {
  const date = new Date();
  const offsetInMinutes = date.getTimezoneOffset();
  const offsetInHours = Math.abs(offsetInMinutes / 60);
  const sign = offsetInMinutes > 0 ? '-' : '+';

  return `${sign}${Math.floor(offsetInHours).toString()}`;
};

interface FormatDateWithIntlObj {
  year: string;
  month: string;
  day: string;
  time: string;
}

const formatDateWithIntl = (dateString: string, timeZone?: string): FormatDateWithIntlObj => {
  const date = new Date(dateString);

  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timeZone === 'UTC' ? 'UTC' : undefined,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });

  const parts = formatter.formatToParts(date);
  const year = parts.find((part) => part.type === 'year')?.value ?? '';
  const month = parts.find((part) => part.type === 'month')?.value ?? '';
  const day = parts.find((part) => part.type === 'day')?.value ?? '';

  const hour = parts.find((part) => part.type === 'hour')?.value;
  const minute = parts.find((part) => part.type === 'minute')?.value;
  const second = parts.find((part) => part.type === 'second')?.value;
  const dayPeriod = parts.find((part) => part.type === 'dayPeriod')?.value;

  const time = `${hour ?? ''}:${minute ?? ''}:${second ?? ''} ${dayPeriod?.toUpperCase() ?? ''}`;

  return { year, month, day, time };
};

export { isValidDate, formatDate, formatDateTime, getMinutesInCurrentMonth, formatDateWithIntl, getGMTInfo };
