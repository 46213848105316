import { FormEvent } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Stack } from '@mui/material';

import { Button } from 'app-zephyr-components/Button';
import { CreateUserTokenReq } from 'ze-api-contract/user-auth-token/user-auth-token.interface';
import { createTokenFormSchemaUI } from 'ze-api-contract/user-auth-token/validation';
import { TooltipWrapper } from 'app-zephyr-components/Tooltip-wrapper';

import { FormContainer } from '../../_elements';
import { FormInput, FormTextArea, FormAutocomplete } from '../../controls';
import { useStyles } from './styles';
import { EXPIRE_TIME_LABELS, WEEK, MONTH, QUARTER, PERMANENT } from './utils';
import { TokenCreate } from './interfaces';

const DEFAULT_FORMS_VALUE = {
  name: '',
  expiryDays: { label: EXPIRE_TIME_LABELS.WEEK, value: WEEK.toString() },
  description: '',
};

export type TokenFormFields = TokenCreate & Record<string, string>;

interface TokenProps {
  onSubmit: (value: CreateUserTokenReq) => Promise<void>;
  onCancel: () => void;
}

const GenerateUserTokenForm = ({ onSubmit, onCancel }: TokenProps) => {
  const { classes } = useStyles();
  const {
    register,
    control,
    reset,
    formState: { errors, isValid, isDirty },
    setValue,
    getValues,
    trigger,
  } = useForm<TokenFormFields>({
    defaultValues: DEFAULT_FORMS_VALUE as TokenFormFields,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(createTokenFormSchemaUI),
  });

  const submitHandler = async (event: FormEvent) => {
    event.preventDefault();
    const data = getValues();
    await onSubmit({
      ...data,
      expiryDays: Number(data.expiryDays.value),
    });
  };

  return (
    <form
      onSubmit={(event) => {
        void submitHandler(event);
      }}
      data-e2e={'GENERATE_USER_TOKEN_FORM'}
    >
      <FormContainer title={'Generate token'}>
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={2}>
            <FormInput<TokenFormFields> id="name" name="name" label="Name" register={register} errors={errors} />
            <FormAutocomplete
              id="expiryDays"
              trigger={trigger}
              setValue={setValue}
              control={control}
              name="expiryDays"
              options={[
                { label: EXPIRE_TIME_LABELS.WEEK, value: WEEK.toString() },
                { label: EXPIRE_TIME_LABELS.MONTH, value: MONTH.toString() },
                { label: EXPIRE_TIME_LABELS.QUARTER, value: QUARTER.toString() },
                { label: EXPIRE_TIME_LABELS.PERMANENT, value: PERMANENT.toString() },
              ]}
              errors={errors}
              label="Expiration time"
            />
          </Stack>

          <FormTextArea<TokenFormFields>
            id="description"
            name="description"
            label="Description"
            register={register}
            errors={errors}
          />
        </Stack>
      </FormContainer>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} marginTop={2}>
        <TooltipWrapper title={'Navigate back to existing api token list.'}>
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              onCancel();
            }}
          >
            Cancel
          </Button>
        </TooltipWrapper>
        <Button type="submit" disabled={!isValid || !isDirty} className={classes.confirmBtn}>
          Generate
        </Button>
      </Stack>
    </form>
  );
};

export { GenerateUserTokenForm };
