import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  link: {
    color: theme.palette.brand.purple[400],
    fontSize: '14px',
    textDecoration: 'underline',
    textUnderlineOffset: '4px',
    transition: 'all ease .03',
    padding: 4,
    border: theme.palette.border.none,

    '&:hover': {
      color: theme.palette.tx.primary,
    },

    '&:focus': {
      color: theme.palette.brand.purple[400],
    },

    '&.white': {
      color: `${theme.palette.tx.primary} !important`,

      '&.focus-border': {
        '&:focus': {
          border: `1px solid ${theme.palette.tx.secondary.default}`,
        },
      },

      '&:hover': {
        color: theme.palette.tx.secondary.default,
      },

      '&:focus': {
        borderRadius: 8,
      },
    },

    '&.focus-border': {
      '&:focus': {
        border: theme.palette.border.purple[400],
        borderRadius: 8,
      },
    },
  },
}));
