import { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Stack } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import { Button } from 'app-zephyr-components/Button';
import { FormInput } from 'app-zephyr-forms';
import { TrashIcon } from 'app-zephyr-icons/Trash';

import { useStyles } from './styles';

export type ApplicationEnvironmentFormFields = { customDnsNames: string[] } & Record<string, string>;

interface EnvironmentDnsNamesFormProps {
  onDomainConnect: () => void;
  onRemoveDomain: (value: string) => void;
  dnsNameList: string[];
  isConnectionPending: boolean;
  isRemovingPending: boolean;
}

export const EnvironmentDnsNamesForm = ({
  onDomainConnect,
  dnsNameList,
  onRemoveDomain,
  isConnectionPending,
  isRemovingPending,
}: EnvironmentDnsNamesFormProps) => {
  const { classes } = useStyles();

  const { register, handleSubmit, control, getValues, reset, trigger } = useForm<ApplicationEnvironmentFormFields>({
    defaultValues: { customDnsNames: dnsNameList } as ApplicationEnvironmentFormFields,
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const { fields } = useFieldArray<ApplicationEnvironmentFormFields>({
    control,
    name: 'customDnsNames' as never,
  });

  useEffect(() => {
    reset({ customDnsNames: dnsNameList } as ApplicationEnvironmentFormFields);
  }, [dnsNameList, reset]);

  const handleOnSubmit = handleSubmit(() => {
    onDomainConnect();
  });

  return (
    <form onSubmit={(data) => void handleOnSubmit(data)} onChange={() => void trigger()}>
      <Stack spacing={1}>
        <p>Custom DNS name</p>
        <Stack className={classes.contentBox}>
          {!dnsNameList.length && <p>Connect Domain to add custom DNS name</p>}
          {!!dnsNameList.length && (
            <Stack spacing={3}>
              {fields.map((item, index) => {
                return (
                  <Stack
                    key={`custom-dns-name-${index.toFixed()}`}
                    direction={'row'}
                    alignItems={'flex-end'}
                    spacing={2}
                    justifyContent={'space-between'}
                  >
                    <FormInput
                      id="customDnsNames"
                      name={`customDnsNames.${index.toString()}`}
                      register={register}
                      errors={{}}
                      disabled={true}
                      placeholder="Fill custom DNS"
                    />
                    <Button
                      variant={'contained'}
                      color={'error'}
                      onClick={() => {
                        onRemoveDomain(getValues().customDnsNames[index]);
                      }}
                      disabled={isRemovingPending}
                    >
                      <TrashIcon />
                    </Button>
                  </Stack>
                );
              })}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} marginTop={2}>
        <Button disabled={isConnectionPending} type="submit">
          {isConnectionPending ? (
            <>
              <RotateLeftIcon className={classes.loading} /> Connect Domain
            </>
          ) : (
            'Connect Domain'
          )}
        </Button>
      </Stack>
    </form>
  );
};
