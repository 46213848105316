import { FormEvent, useEffect, useMemo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Stack } from '@mui/material';

import { Button } from 'app-zephyr-components/Button';
import { formSchema, CreateApplicationTagForm } from 'ze-api-contract/application-v2/application-tag/create';
import { UpdateApplicationTagForm } from 'ze-api-contract/application-v2/application-tag/update';
import {
  ApplicationTagConditionOptions,
  ConditionItem,
} from 'ze-api-contract/application-v2/application-tag/get-condition-options';
import { Channel, channelList, ChannelTooltips } from 'ze-api-contract/application-v2/application-tag/interfaces';
import { TYPE_LABELS } from 'app-zephyr-domains/application/application-tag';
import { TrashIcon } from 'app-zephyr-icons/Trash';
import { VersionItem } from 'app-zephyr-components/Version-item';
import { TooltipWrapper } from 'app-zephyr-components/Tooltip-wrapper';

import { FormContainer } from '../../_elements';
import { FormInput, FormMultiselect } from '../../controls';
import { useStyles } from './styles';

type FormType = 'EDIT' | 'CREATE';

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum TAG_CONDITIONS {
  branch = 'text',
  version = 'text',
  user = 'multiselect',
  isCI = 'multiselect',
  target = 'text',
}

export type ApplicationTagFormFields = CreateApplicationTagForm & Record<string, string>;

interface ApplicationAddTagProps {
  versionValue?: {
    version?: string;
    remote_host?: string;
    initialVersion: string;
    initial_remote_host: string;
  };
  onSubmit: (value: CreateApplicationTagForm | UpdateApplicationTagForm) => Promise<void>;
  onFormChange: (value: CreateApplicationTagForm | UpdateApplicationTagForm) => void;
  onFormReset: () => void;
  conditions: ApplicationTagConditionOptions;
  tag?: CreateApplicationTagForm | UpdateApplicationTagForm;
  type: FormType;
}

function isEditFormType(type: FormType): boolean {
  return type === 'EDIT';
}

function isFormDirty(
  formValue: CreateApplicationTagForm | UpdateApplicationTagForm,
  initialState?: CreateApplicationTagForm | UpdateApplicationTagForm,
): boolean {
  if (formValue.name !== initialState?.name) return true;
  if (formValue.conditions.length !== initialState.conditions.length) return true;
  let res = false;
  formValue.conditions.forEach((item, index) => {
    if (item.type.value !== initialState.conditions[index].type.value) res = true;
    if (item.value.value !== initialState.conditions[index].value.value) res = true;
  });
  return res;
}

// todo: in component is missed skeletons, error ui and empty states
const ApplicationTagForm = ({
  versionValue,
  onSubmit,
  onFormChange,
  onFormReset,
  conditions,
  tag,
  type,
}: ApplicationAddTagProps) => {
  const { classes, cx } = useStyles();
  const {
    register,
    control,
    reset,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
  } = useForm<ApplicationTagFormFields>({
    defaultValues: tag as ApplicationTagFormFields,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(formSchema),
  });

  const { fields, append, remove } = useFieldArray<ApplicationTagFormFields>({
    control,
    name: 'conditions' as never,
  });

  useEffect(() => {
    if (!tag || !isEditFormType(type)) return;
    setValue('conditions', tag.conditions, { shouldValidate: true });
    setValue('name', tag.name, { shouldValidate: true });
  }, [setValue, tag]);

  const initialState = useMemo(() => {
    if (!isEditFormType(type)) return;
    return tag;
  }, [tag]);

  const submitHandler = async (event: FormEvent) => {
    event.preventDefault();
    const data = getValues();
    const conditions = data.conditions.filter((item) => item.value.value);
    const req = {
      ...data,
      conditions,
    };
    await onSubmit(req);
  };

  const getTypeConditions = (): ConditionItem[] => {
    return channelList.map((channel) => {
      return { value: channel, label: TYPE_LABELS[channel] };
    });
  };

  const getValueConditions = (index: number) => {
    const channel = getValues().conditions[index].type;
    return conditions[channel.value];
  };

  const getInputType = (index: number): 'text' | 'multiselect' => {
    return TAG_CONDITIONS[getValues().conditions[index].type.value as keyof typeof TAG_CONDITIONS];
  };

  const isAddConditionDisabled = (): boolean => {
    const data = getValues();
    // todo: should be fixed in seperate pr
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return data.conditions?.some((item) => !item.value.value);
  };

  return (
    <form
      onSubmit={(event) => {
        void submitHandler(event);
      }}
      onChange={() => {
        onFormChange(getValues());
      }}
    >
      <FormContainer title={isEditFormType(type) ? 'Edit tag' : 'Create tag'}>
        <Stack spacing={4}>
          <Stack spacing={1}>
            <FormInput<ApplicationTagFormFields>
              id="name"
              name="name"
              label="Tag name"
              register={register}
              errors={errors}
              placeholder="Tag name should be recognizable and relatable. Ex: team member name, branch etc."
            />
            <div className={cx([classes.versionBox, isEditFormType(type) ? 'edit' : ''])}>
              <VersionItem
                title="Current tag version: "
                version={versionValue?.initialVersion}
                link={versionValue?.initial_remote_host}
              />

              {versionValue?.version && (
                <VersionItem title="New tag version: " version={versionValue.version} link={versionValue.remote_host} />
              )}
            </div>
          </Stack>

          <Stack>
            <span className={classes.title}>Tag conditions</span>
          </Stack>
          {fields.map((item, index) => {
            return (
              <Stack
                spacing={1}
                direction={'row'}
                key={item.id}
                alignItems={errors.conditions?.[index] ? 'flex-start' : 'flex-end'}
              >
                <FormMultiselect
                  id="condition"
                  name={`conditions.${index.toString()}`}
                  placeholder="Define the condition"
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  trigger={trigger}
                  onChange={() => {
                    onFormChange(getValues());
                  }}
                  onFirstSelectChange={(data) => {
                    getInputType(index);
                    // fix rule @typescript-eslint/restrict-template-expression
                    // eslint-disable-next-line
                    setValue(`conditions.${index}.value`, { label: '', value: '' });
                  }}
                  options={{
                    type: getTypeConditions(),
                    value: getValueConditions(index),
                  }}
                  errors={errors}
                  inputType={getInputType(index)}
                  disabled={getValues().conditions[index].type.value === Channel.Target}
                  tooltip={ChannelTooltips[getValues().conditions[index].type.value]}
                />
                {/* TODO: disable this button when the channel type is 'target'. */}
                {fields.length > 1 && (
                  <Button
                    variant={'contained'}
                    color={'error'}
                    onClick={() => {
                      remove(index);
                      onFormChange(getValues());
                    }}
                  >
                    <TrashIcon />
                  </Button>
                )}
              </Stack>
            );
          })}
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <Button
              variant={'contained'}
              color={'secondary'}
              sx={{ width: 'fit-content' }}
              disabled={isAddConditionDisabled()}
              onClick={() => {
                append({
                  type: { label: TYPE_LABELS.user, value: Channel.User },
                  value: { label: '', value: '' },
                });
                onFormChange(getValues());
              }}
            >
              &#xFF0B; Add
            </Button>
          </Stack>
        </Stack>
      </FormContainer>
      <Stack display={'flex'} flexDirection={'row'} gap={2} justifyContent={'flex-end'} marginTop={2}>
        <TooltipWrapper title={'Navigate back to existing application tag list.'}>
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              onFormReset();
            }}
          >
            Cancel
          </Button>
        </TooltipWrapper>
        <Button type="submit" disabled={!isValid || !isFormDirty(getValues(), initialState)}>
          {isEditFormType(type) ? 'Save changes' : 'Create tag'}
        </Button>
      </Stack>
    </form>
  );
};

export { ApplicationTagForm };
