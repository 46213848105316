import { useInfiniteQuery, InfiniteData, QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  GET_APPLICATION_TAG_LIST,
  get_application_tag_list_path,
} from 'ze-api-contract/application-v2/application-tag/list';
import { ApplicationTagListResponse } from 'ze-api-contract/application-v2/application-tag/interfaces';
import { axios } from 'app-zephyr-axios';

const defaultMeta = {
  currentPage: 0,
  lastPage: 0,
  next: 0,
  perPage: 0,
  prev: null,
  total: 0,
};

type TagListQueryOptionsType = UseInfiniteQueryOptions<
  ApplicationTagListResponse,
  Error,
  InfiniteData<ApplicationTagListResponse, number>,
  ApplicationTagListResponse,
  QueryKey,
  number | undefined
>;

export interface GetApplicationTagListParams {
  organization?: string;
  project?: string;
  application?: string;
}

export const applicationTagListQuery = createQueryKeys('application-tag', {
  getApplicationTagList: ({ organization, project, application }: GetApplicationTagListParams) => ({
    queryKey: [GET_APPLICATION_TAG_LIST, organization, project, application],
    queryFn: async ({ pageParam = 1 }) => {
      if (!application || !organization || !project)
        return Promise.resolve({ entities: [], pageMetadata: defaultMeta });

      const url = get_application_tag_list_path({ organization, project, application });
      return axios
        .get<ApplicationTagListResponse>(url, {
          params: {
            page: pageParam,
          },
        })
        .then((res) => res.data);
    },
  }),
});

export function useApplicationTagList({
  params,
  queryParams,
}: {
  params: GetApplicationTagListParams;
  queryParams: { page: number };
}) {
  const queryOptions: TagListQueryOptionsType = {
    ...applicationTagListQuery.getApplicationTagList({ ...params, ...queryParams }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => (lastPage.pageMetadata.next ? pages.length + 1 : undefined),
    getPreviousPageParam: (firstPage, pages) => (pages.length > 1 ? pages.length - 1 : undefined),
  };

  const {
    data,
    fetchNextPage: fetchNextAppVersionListPage,
    hasNextPage: hasNextAppVersionListPage,
    isLoading,
    error,
  } = useInfiniteQuery(queryOptions);

  const applicationVersionList = data?.pages.flatMap((page) => page.entities) ?? [];

  return {
    applicationVersionList,
    hasNextAppVersionListPage,
    fetchNextAppVersionListPage,
    isLoading,
    error,
  };
}
